import React, { useCallback, useEffect, useRef, useState } from "react";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import "../../src/components/Report/map-report/india-map/india-map.componentN.scss";
import "./map.scss";
import { india } from "../assets/maps/all-india";
import { district_map } from "../assets/maps/district_map";
import stateIdData from "../mapData/mapDatalatLong.json";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import MapColorLabel from "./MapColorLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  fetchDashboardData,
  fetchMaptatsData,
  fetchMaptatsOtherData,
  fetchSchoolStatsData,
  fetchSchoolStatsIntData,
  fetchStudentStatsData,
  fetchStudentStatsIntData,
  fetchTeachersStatsData,
  fetchTeachersStatsIntData,
} from "../redux/thunks/dashboardThunk";
import { fetchArchiveServicesGraphSchoolData } from "../redux/thunks/archiveServicesThunk";
import {
  nationalWiseName,
  specificSWiseregionType,
  keyLabels,
  keyMap,
  modifiedFilterObjForReset,
  modifiedFilterObjResetDashboard,
} from "../constants/constants";
import { allFilter } from "../redux/slice/schoolFilterSlice";
import { useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { setBackstate, setIsIndiaClicked } from "../redux/slice/mapSlice";
import { removeAllDistrict } from "../redux/thunks/districtThunk";
import { removeAllBlock } from "../redux/thunks/blockThunk";
import { setReserveUpdatedFilter } from "../redux/slice/headerSlice";
highchartsMap(Highcharts);
localStorage.setItem("backstate", "All India/National");
const Map = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const [backstate, setBackstate] = useState(nationalWiseName);
  const backstate = useSelector((state) => state?.mapData?.backstate);
  const [MergeDataFromAllVariable, setMergeDataFromAllVariable] = useState([]);
  const dashIntDataMap = useSelector(
    (state) => state?.MapStats?.data?.data,
    shallowEqual
  );
  const dashData = useSelector(
    (state) => state?.MapStatsPercentage?.data?.data,
    shallowEqual
  );
  const handleSchemesEvent = useSelector(
    (state) => state?.mapData?.grossEData,
    shallowEqual
  );
  const selectedState = useSelector(
    (state) => state?.mapData?.stateName,
    shallowEqual
  );

  const selectedStateForCallAPI = localStorage.getItem("backstate");
  const maxYearId = localStorage.getItem("acData");
  const parsedACData = JSON.parse(maxYearId);
  const selectYearId = parsedACData?.yearId;
  const [selectedEnrollmentType, setSelectedEnrollmentType] =
    useState("elementary");
  const [selectedDropoutType, setSelectedDropoutType] = useState("primary");
  const [selectedTransitionRate, setSelectedTransitionRate] =
    useState("primaryToUpper");
  const [selectedPupilTeacherRatio, setSelectedPupilTeacherRatio] =
    useState("primary");
  const grossEData = useSelector((state) => state?.mapData?.grossEData);
  const resetMap = useSelector((state) => state?.mapData?.resetMap);
  const isIndiaClicked = useSelector((state) => state?.mapData?.isIndiaClicked);
  const [updateGrossEData, setUpdateGrossEData] = useState(
    "Gross Enrollment Ratio"
  );
  const headerSlice = useSelector((state) => state.header);
  const [resetTrigger, setResetTrigger] = useState(false);
  const [loading, setLoading] = useState(true);
  const [backstatesPreventApiCall, setBackstatesPreventApiCall] =
    useState("india");
  const [udiseStateCode, setUdiceStateCode] = useState("");
  const schoolFilter = useSelector(
    (state) => state?.schoolFilter,
    shallowEqual
  );
  let filterObj = structuredClone(schoolFilter);
  const normalize = useCallback(
    (str) => str.trim().toLowerCase().replace(/&/g, "").replace(/\s+/g, " "),
    []
  );
  const intialMapOptions = {
    chart: {
      map: india,
      events: {
        contextmenu: function (e) {
          e.preventDefault();
        },
      },
    },
    title: {
      text: "",
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    mapNavigation: {
      enabled: false,
    },
    tooltip: {
      useHTML: true,
      backgroundColor: "#303841",
      style: {
        color: "white",
        fontSize: "14px",
      },
    },
    series: [
      {
        name: "State",
        type: "map",
        mapData: india,
        allowPointSelect: true,
        cursor: "pointer",
        borderColor: "#000000",
        color: "#fcae91",
        opacity: 1,
        allAreas: true,
        dataLabels: {
          enabled: false,
          format: "{point.name}",
        },
        data: MergeDataFromAllVariable,
        events: {
          click: (e) => {
            dispatch(setBackstate(e.point["hc-key"]));
            setBackstatesPreventApiCall(e.point["hc-key"]);
            localStorage.setItem("backstate", e.point["hc-key"]);
            if (backstatesPreventApiCall === "India") {
              dispatch(setIsIndiaClicked(false));
            } else {
              dispatch(setIsIndiaClicked(true));
            }
          },
        },
      },
    ],
  };
  const [mapOptions, setMapOptions] = useState(intialMapOptions);
  useEffect(() => {
    if (backstate === nationalWiseName) {
      setMapOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            ...prevOptions.series[0],
            data: MergeDataFromAllVariable,
          },
        ],
      }));
    }
  }, [MergeDataFromAllVariable]);

  useEffect(() => {
    if (MergeDataFromAllVariable.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [MergeDataFromAllVariable]);

  useEffect(() => {
    if (selectedState === nationalWiseName) {
      dispatch(setBackstate("All India/National"));
      setBackstatesPreventApiCall("India");
      setMapOptions(intialMapOptions);
    } else if (selectedState) {
      dispatch(setBackstate(selectedState));
      setBackstatesPreventApiCall("selectedState");
      setState(selectedState);
    }
  }, [selectedState]);

  useEffect(() => {
    if (grossEData === "gross_enrollment_ratio") {
      setUpdateGrossEData("Gross Enrollment Ratio");
    } else if (grossEData === "dropout_rate") {
      setUpdateGrossEData("Dropout Rate");
    } else if (grossEData === "transition_rate") {
      setUpdateGrossEData("Transition Rate");
    } else if (grossEData === "pupil_teacher_ratio") {
      setUpdateGrossEData("Pupil Teacher Ratio");
    } else if (grossEData === "schools_with_drinking_water") {
      setUpdateGrossEData("Schools with Drinking Water");
    } else if (grossEData === "schools_with_electricity_connection") {
      setUpdateGrossEData("Schools with Electricity Connection");
    }
  }, [grossEData]);

  const getSelectedType = useCallback(() => {
    switch (handleSchemesEvent) {
      case "gross_enrollment_ratio":
        return selectedEnrollmentType;
      case "dropout_rate":
        return selectedDropoutType;
      case "transition_rate":
        return selectedTransitionRate;
      case "pupil_teacher_ratio":
        return selectedPupilTeacherRatio;
      default:
        return null;
    }
  }, [
    handleSchemesEvent,
    selectedEnrollmentType,
    selectedDropoutType,
    selectedTransitionRate,
    selectedPupilTeacherRatio,
  ]);

  const getFilteredKey = useCallback(
    (handleSchemesEvent, selectedType) => {
      return (
        keyMap[handleSchemesEvent]?.[selectedType] ||
        keyMap[handleSchemesEvent]?.default ||
        null
      );
    },
    [handleSchemesEvent]
  );
  const getColorFromData = useCallback(
    (additionalData) => {
      const getColor = (a, thresholds, reversed = false) => {
        if (reversed) {
          return a <= thresholds[0]
            ? "#c1d0b5"
            : a <= thresholds[1]
              ? "#e3d1f8"
              : a <= thresholds[2]
                ? "#ffeda0"
                : "#fcae91";
        } else {
          return a <= thresholds[0]
            ? "#fcae91"
            : a <= thresholds[1]
              ? "#ffeda0"
              : a <= thresholds[2]
                ? "#e3d1f8"
                : "#c1d0b5";
        }
      };

      let a = 0;
      const selectedKey = getFilteredKey(handleSchemesEvent, getSelectedType());

      if (selectedKey) {
        a = additionalData[selectedKey] || 0;
      }

      let color = "#FFFFFF";
      switch (handleSchemesEvent) {
        case "gross_enrollment_ratio":
          color = getColor(a, [85, 90, 95]);
          break;
        case "dropout_rate":
          color = getColor(a, [5, 10, 15], true);
          break;
        case "transition_rate":
          color = getColor(a, [85, 90, 95]);
          break;
        case "pupil_teacher_ratio":
          color = getColor(a, [30, 35, 40], true);
          break;
        case "schools_with_drinking_water":
          color = getColor(a, [85, 90, 95]);
          break;
        case "schools_with_electricity_connection":
          color = getColor(a, [70, 80, 90]);
          break;
        default:
          break;
      }

      return { color };
    },
    [handleSchemesEvent, getFilteredKey, getSelectedType]
  );

  useEffect(() => {
    if (dashData?.length > 0 && dashIntDataMap?.length > 0) {
      const normalize = (str) =>
        str.trim().toLowerCase().replace(/&/g, "").replace(/\s+/g, " ");

      const selectedType = getSelectedType();
      const updatedSeriesData = india.features.map((feature) => {
        const regionName = normalize(feature.properties.name);
        const additionalData = { regionName: feature.properties.name };

        [dashData, dashIntDataMap].forEach((dataList) => {
          const matchingData = dataList.find(
            (item) => normalize(item.regionName) === regionName
          );

          if (matchingData) {
            Object.keys(matchingData).forEach((key) => {
              if (key === "regionName") {
                additionalData[key] = matchingData[key];
              } else {
                additionalData[key] = parseFloat(matchingData[key] || 0);
              }
            });
          }
        });

        const color = getColorFromData(additionalData).color;

        return {
          "hc-key": feature.properties["hc-key"],
          name: feature.properties.name,
          additionalData,
          color,
        };
      });

      const filteredData = updatedSeriesData.map((item) => {
        const keyToInclude = getFilteredKey(handleSchemesEvent, selectedType);
        const filteredAdditionalData = keyToInclude
          ? { [keyToInclude]: item.additionalData[keyToInclude] }
          : {};

        return {
          ...item,
          additionalData: filteredAdditionalData,
        };
      });
      setMergeDataFromAllVariable(filteredData);
    }
  }, [
    dashData,
    dashIntDataMap,
    handleSchemesEvent,
    selectedEnrollmentType,
    selectedDropoutType,
    selectedTransitionRate,
    selectedPupilTeacherRatio,
    getColorFromData,
    getFilteredKey,
    getSelectedType,
    resetTrigger,
  ]);

  useEffect(() => {
    if (backstate === nationalWiseName) {
      setMapOptions((prevMapOptions) => ({
        ...prevMapOptions,
        tooltip: {
          ...prevMapOptions.tooltip,
          formatter: function () {
            const normalize = (str) =>
              str.trim().toLowerCase().replace(/&/g, "").replace(/\s+/g, " ");

            const point = this.point;
            const regionName = normalize(point.name);
            const matchingRegionData = MergeDataFromAllVariable.find(
              (item) => normalize(item.name) === regionName
            );

            // Tooltip content starts with the region name
            let tooltipContent = `<div class="tooltip-content"><strong>${"State"}:</strong> <span class="tooltip-content-text">${point.name
              }</span></div>`;

            // Key-to-label mapping object

            if (matchingRegionData) {
              Object.keys(matchingRegionData.additionalData).forEach((key) => {
                if (matchingRegionData.additionalData[key] !== undefined) {
                  const label = keyLabels[key] || key;
                  tooltipContent += `<br/><strong>${label}:</strong> <span class="tooltip-content-text">${matchingRegionData.additionalData[key] || 0
                    } ${key === "ptrPry" || key === "ptrUPry" ? "" : "%"}</span>`;
                }
              });
            }

            return tooltipContent;
          },
        },
      }));
    }
  }, [
    updateGrossEData,
    dashData,
    dashIntDataMap,
    MergeDataFromAllVariable,
    resetTrigger,
  ]);

  const setState = useCallback((stateName) => {
    const selectedState = stateIdData.stateData.find(
      (state) => normalize(state.stateName) === normalize(stateName)
    );
    if (selectedState) {
      setUdiceStateCode(selectedState?.udiseStateCode);
    }
    const selectedMapDisData = district_map.find(
      (data) => normalize(data.name) === normalize(stateName)
    );
    localStorage.setItem("map_state_name", stateName)
    localStorage.setItem("backstate", stateName);
    if (selectedMapDisData) {
      setMapOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            type: "map",
            joinBy: "id",
            mapData: selectedMapDisData.data[0].mapData,
            data: selectedMapDisData.data[0].mapData.map((district) => ({
              id: district.id,
              name: district.name,
              color: "#fff",
              borderColor: "#000000",
            })),
          },
        ],
        chart: {
          height: "480",
          marginTop: 25,
        },
        title: {
          text: "",
        },
        tooltip: {
          useHTML: true,
          backgroundColor: "#303841",
          style: {
            color: "white",
            fontSize: "14px",
          },
          headerFormat: "",
          pointFormat:
            '<br/><strong>Districts:</strong> <span class="tooltip-content-text">{point.name}',
        },
        plotOptions: {
          series: {
            allowPointSelect: true,
            cursor: "pointer",
          },
        },
      }));
    }
  });

  const resetToIndiaMap = () => {
    setMapOptions(intialMapOptions);
    setResetTrigger((prev) => !prev);
  };

  // useEffect to initialize map options
  useEffect(() => {
    if (resetMap) {
      resetToIndiaMap();
      setResetTrigger((prev) => !prev);
    }
  }, [resetMap]);
  const backToNational = () => {
    dispatch(removeAllDistrict());
    dispatch(removeAllBlock());
    dispatch(setBackstate("All India/National"));
    setBackstatesPreventApiCall("India");
    localStorage.setItem("backstate", nationalWiseName);
    localStorage.setItem("map_state_name", nationalWiseName)
    dispatch(fetchMaptatsData(modifiedFilterObjForReset));
    dispatch(fetchMaptatsOtherData(modifiedFilterObjForReset));
    handleAPIsCallAccordingToFilter(modifiedFilterObjResetDashboard);
    setUdiceStateCode("");
    dispatch(setIsIndiaClicked(false))
    resetToIndiaMap();
  };

  useEffect(() => {
    if (backstate !== "All India/National") {
      setState(backstate);
    }
  }, [backstate]);

  useEffect(() => {
    if (isIndiaClicked === true) {
      if (selectedStateForCallAPI === nationalWiseName) {
      } else {
        /*--------------------Filter by Particular State ----------------------*/
        if (udiseStateCode) {
          filterObj.regionType = specificSWiseregionType;
          filterObj.regionCode = udiseStateCode;
          filterObj.dType = specificSWiseregionType;
          filterObj.dCode = udiseStateCode;
          filterObj.dashboardRegionType = specificSWiseregionType;
          filterObj.dashboardRegionCode = udiseStateCode;
          if (location.pathname !== "/") {
            filterObj.valueType = 1;
          } else {
            filterObj.valueType = 2;
          }
          filterObj.yearId = selectYearId;
          dispatch(allFilter(filterObj));
          handleAPIsCallAccordingToFilter(filterObj);
        }
      }
    }
  }, [
    location.pathname,
    selectedStateForCallAPI,
    selectYearId,
    isIndiaClicked,
    schoolFilter,
    udiseStateCode,
  ]);
  const handleAPIsCallAccordingToFilter = (obj) => {
    if (headerSlice.headerName === "Education Dashboard") {
      dispatch(fetchDashboardData(obj));
      dispatch(fetchSchoolStatsData(obj));
      dispatch(fetchSchoolStatsIntData(obj));
      dispatch(fetchTeachersStatsData(obj));
      dispatch(fetchTeachersStatsIntData(obj));
      dispatch(fetchStudentStatsData(obj));
      dispatch(fetchStudentStatsIntData(obj));
    } else if (headerSlice.headerName === "School Dashboard") {
      dispatch(fetchSchoolStatsData(obj));
      dispatch(fetchSchoolStatsIntData(obj));
      dispatch(fetchArchiveServicesGraphSchoolData(obj));
    } else if (headerSlice.headerName === "Teacher Dashboard") {
      dispatch(fetchTeachersStatsData(obj));
      dispatch(fetchTeachersStatsIntData(obj));
    } else {
      dispatch(fetchStudentStatsData(obj));
      dispatch(fetchStudentStatsIntData(obj));
    }
  };
  return (
    <>
      {loading && (
        <Box className="map-overley">
          <CircularProgress />
        </Box>
      )}
      <div className="map-dropdown">
        {handleSchemesEvent === "gross_enrollment_ratio" && (
          <select
            value={selectedEnrollmentType}
            onChange={(e) => setSelectedEnrollmentType(e.target.value)}
            className="form-control"
          >
            <option value="elementary">Elementary</option>
            <option value="secondary">Secondary</option>
          </select>
        )}

        {handleSchemesEvent === "dropout_rate" && (
          <select
            value={selectedDropoutType}
            onChange={(e) => setSelectedDropoutType(e.target.value)}
            className="form-control"
          >
            <option value="primary">Primary</option>
            <option value="secondary">Secondary</option>
          </select>
        )}

        {handleSchemesEvent === "transition_rate" && (
          <select
            value={selectedTransitionRate}
            onChange={(e) => setSelectedTransitionRate(e.target.value)}
            className="form-control"
          >
            <option value="primaryToUpper">Primary to Upper Primary</option>
            <option value="upperToSec">Upper Primary to Secondary</option>
          </select>
        )}

        {handleSchemesEvent === "pupil_teacher_ratio" && (
          <select
            value={selectedPupilTeacherRatio}
            onChange={(e) => setSelectedPupilTeacherRatio(e.target.value)}
            className="form-control"
          >
            <option value="primary">Primary</option>
            <option value="upperPrimary">Upper Primary</option>
          </select>
        )}
      </div>

      <div className="graph-wrap" id="india-map">
        {backstate !== nationalWiseName && (
          <div className="buttonWrapper">
            <button className="backButton ms-3" onClick={backToNational}>
              <ArrowBackIcon /> Back to India
            </button>
          </div>
        )}
        <HighchartsReact
          constructorType={"mapChart"}
          highcharts={Highcharts}
          options={mapOptions}
          allowChartUpdate={true}
          immutable={true}
        />
      </div>
      <MapColorLabel />
    </>
  );
};

export default Map;

import { useMemo } from "react";

const useGroupedDataWithTotals = (arrGroupedDataTable) => {
  const result = useMemo(() => {
    const groupedDataWithTotals = arrGroupedDataTable.reduce((acc, curr) => {
      const {
        regionName,

      } = curr;

      if (!acc[regionName]) {
        acc[regionName] = {
          records: [],
          totals: {
            regionName: ` ${regionName}`,
            socialCat: "Overall",
            Overall: 0,
            total: 0,
            // Initialize all totals to 0
            ...Object.fromEntries(
                Object.keys(curr).filter(key => key.includes('Rate')).map(key => [key, 0])
            ),
            isTotalRow: true,
          },
        };
      }

      acc[regionName].records.push(curr);

        Object.keys(curr).filter(key => key.includes('Rate')).forEach(key => {
            acc[regionName].totals[key] += parseFloat(curr[key] || 0)
        })

      return acc;
    }, {});

    const finalResult = Object.values(groupedDataWithTotals).reduce(
      (finalArr, { records, totals }) => {
          const totalsWithFixed = {
              ...totals,
              ...Object.fromEntries(
                  Object.keys(totals).filter(key => key.includes('Rate')).map(key => [key, (totals[key] / records.length).toFixed(2)])
              )
          }
        return [...finalArr, ...records, totalsWithFixed];
      },
      []
    );
    return finalResult;
  }, [arrGroupedDataTable]);

  return result;
};

export default useGroupedDataWithTotals;